.text-input-wrapper{
    display: flex;
    flex-direction: column;
}

.text-input-label{
    color: #00924e;
    background: none;
    border-radius: 5px 5px 0px 0px;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
}

.text-input-field:disabled {
    color:#888888;
    border:0px;
}

.text-input-field{
    width: 100%;
    font-size: 13px;
    color: #333333;
    outline-color: rgb(40, 134, 103);
    outline-width: 3px;
    box-sizing: border-box;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    padding: 8px 6px;
    margin: 0px 1px 3px 0px;
    border: 1px solid #006838;
    border-radius: 0px;
    /* border: 0px; */
    background: #eef3f0;
    /* border-bottom: 1px solid #999999;*/
    font-family: 'Raleway', sans-serif;
}