/* Layout 
nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: #888;
  }
  
  sidebar{
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 200px;
    background: #999;
  }
  
  .table-main{
    position: absolute;
    top: 50px;
    left: 200px;
    min-height: 500px;
    right: 0;
    bottom: 0;
    background: #CCC;
    padding: 1rem;
  }
  
  #content{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* This is what we are focused on 
  .table-wrapper{
    overflow-y: scroll;
    overflow-x:auto;
    flex-grow: 1;
  }
  
  .table-wrapper th{
      position: sticky;
      top: 0;
      border:1px solid #827f7c;
  }
  
   /*A bit more styling to make it look better 
  .table-wrapper{
    background: CadetBlue;
  }
  
  table{
    border-collapse: collapse;
    width: 100%;
  }
  
  th{
      background: #DDD;
  }
  
  td,th{
    padding: 10px;
    text-align: center;
  }

  
   tbody tr:nth-child(even) {background-color: #f2f2f2;}*/
  

   /*.Table
   {
       display: table;
   }
   .Title
   {
       display: table-caption;
       text-align: center;
       font-weight: bold;
       font-size: larger;
   }
   .Heading
   {
       display: table-row;
       font-weight: bold;
       text-align: center;
   }
   .Row
   {
       display: table-row;
   }
   .Cell
   {
       display: table-cell;
       border: solid;
       border-width: thin;
       padding-left: 5px;
       padding-right: 5px;
   }*/

   /* Variables
================================== */
/* Tables
================================== */
.Table {
    /*display: -webkit-box;
    display: flex;*/
    font-size:13px;
    min-height:300px;
    position: relative;
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    /*margin-left: 20px;*/
    transform: scale(1.0);
    overflow-y:scroll;  
    min-width: 300px;
    width:fit-content;  
    /*max-width: 550px;*/
    overflow-x: auto;
    max-height:100vh;
    /*width: 100%;
    width: 200%;*/
    transform: translateX(-25%) translateY(-25%) scale(0.5);
  }
  .cell {
    box-sizing: border-box;
    -webkit-box-flex: 1;
            flex-grow: 1;
    width: 100%;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style: none;
    border: solid 3px white;
    background: rgba(112, 128, 144, 0.2);
  }

  
  
  .Title{
      font-size: 15px;
      font-weight: bold;
      padding: 5px 2px;
      
  }

  .Title-row{
    box-sizing: border-box;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    position:relative;
    flex-flow: wrap;   
    border: dotted #333333;
    border-width: 0px;
    color: #333333;
    
    /* background-color: #d2d2d2; */
    padding: 5px 2px;
  }
  .Header-wrapper{
    position:sticky;  
    z-index: 6;  
    top:0px;    
    min-width:450px;
    /*box-shadow: 1px 1px 3px #333333;*/ /*#009944;*/
  }

  .Body-wrapper{
    z-index: 2; 
    min-width:450px;
  }

  .Row{
    box-sizing: border-box;
    display: inline-flex;
    position:relative;
    /*flex-flow: wrap;*/
    border: solid #333333;
    border-width: 0px 1px 1px 1px;
    background-color: #ffffff;
  }

  .Table .Row:nth-child(even) {background-color: #f2f2f2;}

  .Row-error{
    border: solid #ff0000;
    border-width: 0px 1px 1px 1px;
    background-color: #ffbcbc;
  } 

  .Row-error .Cell::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0.75em solid #992b00;
    border-left: 0.75em solid transparent;
    border-top: 0.75em solid transparent;
  }

  Table .Row:nth-child(even):hover{
    background-color: #d3ffe4;
  }
  
  .Cell{
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    width: 33.333%;
    min-width: 20px;
    position: relative;
    background:none;
    border-right: dotted 1px #333333;
    border-width: thin;
    padding: 4px;
  }

  .Cell p{      
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }

   .Header{   
    font-weight: bold;
    box-sizing: border-box;
    background:#f5f5f5;
    display: inline-flex;
    /*flex-flow: wrap;*/
    border: solid 1px #333333;
       font-weight: bold;
       text-align: center;
       box-shadow: 1px 1px 3px #333333;
  }
     
  .Row-header{
    position: sticky;
    left: 0px;
    z-index:5;
    background: #d2d2d2;
    color: #333333;
    font-weight: bold;
   
    /*border-bottom:1px solid #777777;*/
  }

  .Header .Cell {
    border-right: solid 1px #333333;
  }
  
  .resizer {
    display: inline-block;
    background: rgb(120, 120, 150);
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(0%);
    z-index: 1;
    touch-action:none;  
    cursor: ew-resize;  
  }

  .resizer:hover {
    display: inline-block;
    background: rgb(120, 120, 150);
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;

    
  }

  .pagination {
    display: inline-block;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }

  .colspan2{
    flex: 2;
  }
  .Rtable-cell > h1,
  .Rtable-cell > h2,
  .Rtable-cell > h3,
  .Rtable-cell > h4,
  .Rtable-cell > h5,
  .Rtable-cell > h6 {
    margin: 0;
  }