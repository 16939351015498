.navigation-bar{
    width: 100%;
    height:50px;
    background-color: #f7fdfb;;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: fixed;
    z-index: 20;
    box-shadow: 0px 2px 5px rgb(13 13 13 / 40%);
}

.navigation-bar > div{
    display:flex;
    align-items:center
}

.navigation-bar-button-1{
    outline: none;
    margin: 2px;
    font-size: 15px;
    /*font-weight: bold;*/
    /* color: cornflowerblue; */
    color: #0f75bc;
    /*background-color: #e9f7ff;*/
    background-color: #ffffff;
    padding: 5px 20px;
    border: 0px solid #0f75bc;
    border-radius: 20px;
    box-shadow: 1px 1px 2px rgba(100,100,100,0.4);
}

.navigation-bar-button-2{
    outline: none;
    margin:2px;
    margin-left:10px;
    font-size: 15px;
    /*font-weight: bold;*/
    color: white;
    background-color:#0f75bc;
    padding:5px 20px;
    border: 0px solid #0f75bc;
    border-radius: 20px;
    flex-wrap: nowrap;
    box-shadow: 1px 1px 2px rgba(100,100,100,0.4);
}

.app-title{
    font-weight: bold;
    color: #333333;
    font-size: 22px;
    font-style: italic;
}

.app-logo {
    height: 30px;
    padding: 2px;
    pointer-events: none;
}


/* Side Menu Button */
.side-menu-button-bar{
    display: inline-block;
    margin-bottom: 6px;
    height: 2px;
    background-color: #009944;
    width: 24px;
}

.side-menu-button-bar-1{   
    margin-bottom: 6px;    
}

.side-menu-button-bar-2{   
    margin-bottom: 6px;    
}

.side-menu-button-bar-3{   
    margin-bottom: 1px;   
}





.side-menu-button{
    display: none;
    flex-direction: column;
    margin: 5px 5px 5px 10px;
    cursor: pointer;
    background:none;
    padding: 10px;
    border-radius: 5px;

    padding: 8px;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
}

.organisation-logo{
    height: 40px;
    /* width: 40px; */
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}



@media screen and (max-width: 550px) {
    .side-menu-button-active{
        background: #bbf5ce;
        padding: 0px;
    }

    .side-menu-button-bar-1-active{   
        margin:0px;    
        transform:rotate(-45deg) translate(-8px, 16px);
        width:24px;
        transition: all .3s ease-in;
    }
    
    .side-menu-button-bar-2-active{   
        display:none;
    }
    
    .side-menu-button-bar-3-active{     
        margin:4px;    
        transform:rotateZ(45deg) translate(8px, 7px);
        width:24px;
        transition: all .3s ease-in;
    }

    .side-menu-button{
        display: flex;
    }
}