.centered-view {
    min-height: calc(100vh - 50px);
    top: 50px;
    /*padding-top: 50px;*/
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    /*background: linear-gradient(40deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);*/
    /*background:tomato;*/
    background:cadetblue;
    background:#e7f2f3;
    position: relative;
}

.offset-centered-view{
    padding-left:0px;
}

@media screen and (min-width: 550px) {
    /*.centered-view{
        padding-left:250px;
    }*/

    .offset-centered-view{
        padding-left:250px;
    }
}