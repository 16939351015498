.pagination-button{
  margin: 2px;
  border-radius: 4px;
  font-size: 12px;
  color: #009944;
  background-color: #e0e0e0; /* #daeada; */
  border:0px solid #009944;  
  /*box-shadow: 1px 1px 1px rgba(200,200,200,0.6);*/
  float: left;
  padding: 4px 10px;
  text-decoration: none;
  font-weight:bold;
  cursor: pointer;
}

.pagination-button-previous:after{
  display:block;
  width:16px;
  height:16px;
  content:url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><path d='M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z'/><path d='M0 0h18v18H0z' fill='none'/></svg>")
}

.pagination-button-next:after{
  color:#ffffff;
  display:block;
  width:16px;
  height:16px;
  content:url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><path d='M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z'/><path d='M0 0h18v18H0z' fill='none'/></svg>")
}

.pagination-button:hover{
  color: #009944;
  background-color: #88bd88;
  border:0px solid #009944;  
  box-shadow: 2px 2px 1px rgba(200,200,200,0.6); 
}

.pagination-button-active{
  color: #ffffff;
  background-color: #008844;
  border:0px solid #009944;   
}