.dropdown-button {
    min-width: 100px;
    max-width:200px;
    overflow:hidden;
    height: 40px;
    border:0px;
    border-radius:0px;
    background-color: #009e4f;
    color:#ffffff;
    font-size:13px;
    padding:5px 10px;
    box-sizing: border-box;
    vertical-align: middle;
    display: flex;
    align-items: center;    
    justify-content: space-between;
}

.dropdown-button .text {
    white-space: nowrap;
    text-overflow: ellipsis;
}
.dropdown-button:hover{
    background-color: #54a34e;
}

/*.dropdown-menu {
    position: absolute;
    float: left;    
    transform:transform(scale(1, 0))
    transition(-webkit-transform ease 250ms)
    transition(transform ease 250ms);
    overflow-y: auto;
    z-index:100000000;
    display: none;    
    max-height: 150px;
    min-width: 150px;
    padding:5px;
    background:#ffffff;
    margin: 2px;
    border: 1px solid #999999;
    border-radius: 5px;
}*/

.dropdown-item {
    z-index:2;
    cursor:pointer;
    background:white;
    font-size:12px;
    color:#555555;
    /*width:100%;*/
    border-bottom: 1px solid #CCCCCC;
    padding:5px;
}

.dropdown-open-icon{   
        display: inline-block;
        width: 0px;
        height: 0px;
        margin-left: 8px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #ffffff;/* rgba(4, 99, 68, 0.8);*/
        transition: transform 0.1s ease-out 0s;
        transform: rotateZ(90deg);
}

.dropdown-item:hover{
    background:#EEEEEE;
}

/*.dropdown-visible{
    display:block;
}*/