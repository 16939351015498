.sub-nav-action-button
{
    color: white;
    background: rgb(0, 153, 68);
    padding: 4px 20px;
    border: 0px;
    margin: 2px 10px;
    font-size: 12px;
    border-radius: 10px;
}

.sub-nav-action-buttons-container{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #565656;
}