.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 80px;
    right: 0;
    z-index: 99999999;
    width: 360px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto; }
    .notification-container .notification {
      min-height: 55px;
      box-sizing: border-box;
      padding: 15px 15px 15px 58px;
      border-radius: 4px;
      color: #fff;
      background-color: #bdc3c7;
      cursor: pointer;
      font-size: 12px;
      border-bottom:5px solid #333333;
      line-height: 12px; /*1.2em;*/
      position: relative;
      /*opacity: 0.9;*/
      margin-top: 15px; }
      .notification-container .notification:before {
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -14px;
        display: block;
        width: 28px;
        height: 28px;
        font-size: 28px;
        text-align: center;
        line-height: 28px; }
      .notification-container .notification .close-button {
        width: 17px;
        height: 17px;
        float: right;
        z-index: 10;
        border-radius: 4px;
        border: none;
        background: white;
        opacity: 0.3; }
        .notification-container .notification .close-button i.close-icon {
          margin: 2px;
          content: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDEzIDEzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMyAxMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTExLjksMi4xTDEwLjgsMUw2LjQsNS4zTDIuMSwxTDEsMi4xbDQuMyw0LjNMMSwxMC44bDEuMSwxLjFsNC4zLTQuM2w0LjMsNC4zbDEuMS0xLjFMNy41LDYuNEwxMS45LDIuMXoiLz4NCjwvc3ZnPg=="); }
        .notification-container .notification .close-button:hover {
          opacity: 1; }
      .notification-container .notification .title {
        font-size: 1em;
        line-height: 1.2em;
        font-weight: bold;
        margin: 0 0 5px 0; }
      .notification-container .notification:hover, .notification-container .notification:focus {
        opacity: 1; }
    .notification-container .notification-enter {
      visibility: hidden;
      transform: translate3d(100%, 0, 0); }
    .notification-container .notification-enter.notification-enter-active {
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition: all 0.4s; }
    .notification-container .notification-leave {
      visibility: visible;
      transform: translate3d(0, 0, 0); }
    .notification-container .notification-leave.notification-leave-active {
      visibility: hidden;
      transform: translate3d(100%, 0, 0);
      transition: all 0.4s; }
    .notification-container .notification-info {
      background-color: #306f98; }
      .notification-container .notification-info:before {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTEgMTVoLTJ2LTZoMnY2em0wLThoLTJWN2gydjJ6Ii8+DQo8L3N2Zz4="); }
    .notification-container .notification-success {
      background-color: #106911; }
      .notification-container .notification-success:before {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggZD0iTTkgMTYuMkw0LjggMTJsLTEuNCAxLjRMOSAxOSAyMSA3bC0xLjQtMS40TDkgMTYuMnoiLz4NCjwvc3ZnPg=="); }
    .notification-container .notification-warning {
      background-color: #e67e22; }
      .notification-container .notification-warning:before {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggZD0iTTEgMjFoMjJMMTIgMiAxIDIxem0xMi0zaC0ydi0yaDJ2MnptMC00aC0ydi00aDJ2NHoiLz4NCjwvc3ZnPg=="); }
    .notification-container .notification-error {
      background-color: #e74c3c; }
      .notification-container .notification-error:before {
        content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTEgMTVoLTJ2LTJoMnYyem0wLTRoLTJWN2gydjZ6Ii8+DQo8L3N2Zz4="); }
    .notification-container .custom-icon:before {
      content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNOSAxMS43NWMtLjY5IDAtMS4yNS41Ni0xLjI1IDEuMjVzLjU2IDEuMjUgMS4yNSAxLjI1IDEuMjUtLjU2IDEuMjUtMS4yNS0uNTYtMS4yNS0xLjI1LTEuMjV6bTYgMGMtLjY5IDAtMS4yNS41Ni0xLjI1IDEuMjVzLjU2IDEuMjUgMS4yNSAxLjI1IDEuMjUtLjU2IDEuMjUtMS4yNS0uNTYtMS4yNS0xLjI1LTEuMjV6TTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgMThjLTQuNDEgMC04LTMuNTktOC04IDAtLjI5LjAyLS41OC4wNS0uODYgMi4zNi0xLjA1IDQuMjMtMi45OCA1LjIxLTUuMzdDMTEuMDcgOC4zMyAxNC4wNSAxMCAxNy40MiAxMGMuNzggMCAxLjUzLS4wOSAyLjI1LS4yNi4yMS43MS4zMyAxLjQ3LjMzIDIuMjYgMCA0LjQxLTMuNTkgOC04IDh6Ii8+DQogICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPg0KPC9zdmc+"); }
  