.popup-menu {
    position: absolute;
    float: left;    
    transform:transform(scale(1, 0))
    transition(-webkit-transform ease 250ms)
    transition(transform ease 250ms);
    overflow-y: auto;
    z-index:1000000;
    display: none;
    max-height:150px;
    min-width: 150px;
    padding:5px;
    background:#ffffff;
    margin: 2px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgba(10,10,10,0.2);
}

.popup-visible{
    display:block;
}