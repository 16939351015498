.inline-modal-container{
    /*background: linear-gradient(45deg, rgb(7, 0, 184) 0%, rgb(0, 255, 136) 100%);*/
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
}

.inline-modal{
    max-width: 350px;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    margin: 20px;
    padding: 10px;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(20,20,20,0.4);
}

.inline-modal-header{
    font-size: 16px;
    font-weight: bold;
    color: rgb(0, 153, 68);
    padding:10px 5px;
}

.inline-modal-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

