body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: "Work Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif; */ 
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: #EEEEEE;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding:0px;
}

label {
  font-size: 13px;
  font-weight: bold;
  padding:6px 2px;
  /*text-transform: uppercase;
  letter-spacing: 1.5px;*/
  color:#555555;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-group {
  display:flex;
  flex-direction:column;
  margin:5px;
}

.help-block {
  font-size:13px;
  color: #b93f3f
}

.has-error{
  color:#FF0000;
  font-weight:bold;
}
.has-error .text-input{
  border:2px solid #FF0000;
}

.text-input {
  width:100%;
  font-size: 13px;
  color:#333333;
  margin:2px;
  outline-color: rgb(40, 134, 103);
  outline-width: 3px;
  padding:4px 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: 8px 6px;
  margin: 5px 1px 3px 0px;
  
  /* border: 1px solid #bdc9bd; */
  /* border-radius: 3px; */
  /*border: 0px;*/
  background: #eef3f0;
  /*border-bottom: 1px solid #999999;*/
}

.text-input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);  
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}

.input-label {
  font-size: 13px;
  color: #009444;
  vertical-align: middle;
}

.middle-v-align {
  vertical-align: middle;
}

.left-h-align {
  text-align: left;
  margin-left: 1em;
}

.width-auto {
  width: auto;
}

table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}

table td {
  white-space: nowrap;
  vertical-align: top;
}

table .absorbing-column {
  width:100%;
}

.button{
  font-size:12px;
  font-weight: bold;
  text-decoration:none;
  cursor:pointer;
  outline:none;
  border-radius:20px;
  border: none;
  padding:5px 20px;
  margin:5px;
}

.button-1{  
  color:#FFFFFF;
  background-color:#00924e;
}

.button-1:hover{  
  background-color:#017738;
}

.button-2{  
  color:#017738;
  background-color:#eeeeee;
  border: 1px solid #017738;
}

.button-2:hover{  
  background-color:#e5ffd0;
}

.button:disabled{
  background-color: #999999;
  color: #555555;
}

.main-item-wrapper{
  display: inline-block;
  /*box-shadow: 2px 2px 10px rgb(30 30 30 / 20%);*/
  border-radius: 10px;
  align-content: center;
  justify-content: center;
  display: flex;
  margin:10px
}

.main-item-wrapper:hover{
  box-shadow: 1px 1px 10px rgb(30 30 30 / 10%);
}

.main-menu-item {
  min-width: 50px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-menu-item .menu-item-icon {
  height: 36px;
  width: 36px;
  pointer-events: none;
}

.main-menu-item:hover {
  transform:scale(1.1);
}

.menu-item-icon:hover {
  height: 42px;
  width: 42px;
}

.main-menu-item .menu-item-button {
  white-space: nowrap;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  font-size: 11px;
  color: rgb(60 58 58);
  background: #e1f1e5;
  border-radius: 30px;
  padding: 5px 16px;
}

.menu-item-button:hover{
  background: rgba(233, 250, 237, 0.8);  
  /*box-shadow: 0px 0px 5px rgba(0,200,100,0.4);*/
  font-weight: bold;
}

.promo-box{
  background: #121FCF;
background: -webkit-linear-gradient(to right, #121FCF 0%, #CF1512 100%);
background: -moz-linear-gradient(to right, #121FCF 0%, #CF1512 100%);
background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: 200px;
    background: rgb(255, 153, 0);
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 20px;
    border-radius: 5px;
}


/* Main Content Style */
.main-content-container{
  padding: 20px;
    max-width: 1000px;
    box-sizing: border-box;
    min-width: 300px;
    width: 100%;
    position: absolute;
    overflow-x: auto;
    overflow-y: auto;
    top: 30px;
    bottom: 0px;
}

.main-content-wrapper{
  background: none;
    position: relative;
    height: calc(100vh - 50px);
    display: flex;
    width: 100%;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

.user-avatar-large{
    background-size: 100%;
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin: 10px;
    border-radius: 50%;
}