.component-search-input {
    /*border-bottom: 1px solid #ccc;*/
  }
  
.component-search-input > div {
  margin: 0 10px 10px 10px;
}

.component-search-input input {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #EFEFEF;
  box-shadow: 0px 0px 5px rgba(200,200,200,0.5);
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px 6px;
  width: 100%;
}

.search-button {
  align-items: center;
  background: #008844 none repeat scroll 0 0;
  border-radius: 0px 4px 4px 0px;
  color:#FFFFFF;
  border:0px;
  padding: 3px 15px;
  transition: all 0.5s ease 0s;
}

.filter-button {
  align-items: center;
  background: #13b405 none repeat scroll 0 0;
  border-radius: 0px;
  color:#FFFFFF;
  border:0px;
  padding: 3px 15px;
  transition: all 0.5s ease 0s;
}

.search-button:hover, .search-button:focus {
background: #15943b;
}
/*.Flatsearch .search-button,
.Flatsearch .search-input {
-webkit-appearance: none;
   -moz-appearance: none;
        appearance: none;
background: transparent;
border: 0;
color: inherit;
font: inherit;
outline: 0;
}*/